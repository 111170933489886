import React, { useEffect, useState } from "react";
import wpAPI from "../../api/wpAPI";
import ArrowToTop from "../common/ArrowToTop";
import Spinner from "../Spinner";

function Coordinadores() {
  // eslint-disable-next-line
  const [coordinadores, setCoordinadores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await wpAPI.get(`/pages?categories=48&per_page=30`);

        await setCoordinadores(
          result && result.data[0] && result.data[0].content.rendered
        );
      } catch (e) { }
    };
    fetchData();
  }, []);

  console.log('coordinadores', coordinadores)

  return coordinadores ? (
    <div className="row d-flex content justify-content-end react-transition fade-in">
      <div className="col-md-3"></div>
      <div className="col-md-9 autores">
        <h2>Coordinadores</h2>
        <div className="row">
          <div className="col-md-6">

            <div
              className="autores"
              dangerouslySetInnerHTML={{
                __html: coordinadores
              }}
            />

          </div>
        </div>
        {/* array.sort((x, y) => x.propertyName > y.propertyName ? 1 : -1)) */}
      </div>
      <ArrowToTop />
    </div>
  ) : (
      <Spinner />
    );
}

export default Coordinadores;

import React from "react";
import logoIMC from "../../assets/images/logos/imc-logo.png";
import ModalAvisoLegal from "../auth/ModalAvisoLegal";
import ModalPoliticas from '../auth/ModalPoliticas';

function Footer() {
  return (
    <>
      <div className="row mt-3">
        <div className="col footer mt-2 d-flex justify-content-center">
          <a
            className="text-center"
            target="blank"
            href="https://www.imc-sa.es/"
          >
            <img style={{ width: "29%" }} src={logoIMC} alt="" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col footer mt-2 mb-5 d-flex justify-content-around">
          <ul className="nav justify-content-between">
            <li className="nav-item">
              {/* <a className="nav-link active" href="./aviso-legal">
                Aviso legal
              </a> */}
              <span type="button" className="nav-link active" data-toggle="modal" data-target="#aviso">Aviso legal</span>
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link" to="/politica-de-privacidad">
                Política de privacidad
              </Link> */}
              <span type="button" className="nav-link active" data-toggle="modal" data-target="#politicas">Política de Privacidad</span>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="mailto:info@habilidadesclinicas.com">
                info@habilidadesclinicas.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ModalPoliticas />
      <ModalAvisoLegal />
    </>
  );
}

export default Footer;

export const PREV = "PREV";
export const NEXT = "NEXT";
export const SET_THEMES = "SET_THEMES";
export const SET_THEME = "SET_THEME";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";

export const SET_USER = "SET_USER";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_USER_FIELD = "UPDATE_USER_FIELD";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";

import { SET_PROFILE, SET_USER, UPDATE_USER_FIELD } from "../types";

export default (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        authUser: action.payload
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case UPDATE_USER_FIELD:
      return {
        ...state,
        profile: {
          ...state.profile,
          [Object.keys(action.payload)]: Object.values(action.payload)
        }
      };
    default:
      return state;
  }
};

import React from "react";
import SumarioCard from "./SumarioCard";

const SumarioByCategory = React.memo(() => {

  return (
    <div className="row mt-5 mb-5 react-transition fade-in">
      <div className="col">
        <div className="row d-flex justify-content-end mb-3">
          <div className="col-md-8 mr-5">
            <h1>Sumario</h1>
          </div>
        </div>

        <div className="row d-flex justify-content-end">
          <div className="col-md-8 mr-5">
            <div className="accordion" id="sumarioAccordion">
              {Array.from({ length: 15 }).map((el, i) => (
                <SumarioCard key={i} localCategoryId={i + 1} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SumarioByCategory;

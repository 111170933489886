import axios from "axios";

export default axios.create({
  // Desarrollo
  // baseURL: "http://localhost:8888/wp-habilidades/wp-json/wp/v2"
  // baseURL: "https://angelcweb3.es/wp-habilidades/wp-json/wp/v2"
  // Preproduccion
  // baseURL: "https://wp-habilidades.imc-sa.es/wp-json/wp/v2",
  // Prodccion
  baseURL: "https://wp.habilidadesclinicas.com/wp-json/wp/v2"
});

// wpAPI.get('/...')

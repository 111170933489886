import React from "react";

function ComboEspecialidades({ register, fieldValue }) {
  return (
    <div className="form-group">
      <label htmlFor="especialidad">Especialidad</label>
      <select
        className="form-control"
        name="especialidad"
        ref={register({ required: true })}
      >
        <option value="Medicina familiar y comunitaria">
          Medicina familiar y comunitaria
                    </option>
        <option value="Alergología">Alergología</option>
        <option value="Anestesiología y reanimación">
          Anestesiología y reanimación
                    </option>
        <option value="Cardiología">Cardiología</option>
        <option value="Gastroenterología">Gastroenterología</option>
        <option value="Endocrinología">Endocrinología</option>
        <option value="Geriatría">Geriatría</option>
        <option value="Hematología y hemoterapia">
          Hematología y hemoterapia
                    </option>
        <option value="Hidrología médica">Hidrología médica</option>
        <option value="Infectología">Infectología</option>
        <option value="Medicina aeroespacial">
          Medicina aeroespacial
                    </option>
        <option value="Medicina del deporte">
          Medicina del deporte
                    </option>
        <option value="Medicina del trabajo">
          Medicina del trabajo
                    </option>
        <option value="Medicina de urgencias">
          Medicina de urgencias
                    </option>
        <option value="Medicina física y rehabilitación">
          Medicina física y rehabilitación
                    </option>
        <option value="Medicina intensiva">
          Medicina intensiva
                    </option>
        <option value="Medicina interna">Medicina interna</option>
        <option value="Medicina legal y forense">
          Medicina legal y forense
                    </option>
        <option value="Medicina preventiva y salud pública">
          Medicina preventiva y salud pública
                    </option>
        <option value="Nefrología">Nefrología</option>
        <option value="Neumología">Neumología</option>
        <option value="Neurología">Neurología</option>
        <option value="Nutriología">Nutriología</option>
        <option value="Oftalmología">Oftalmología</option>
        <option value="Oncología médica">Oncología médica</option>
        <option value="Oncología radioterápica">
          Oncología radioterápica
                    </option>
        <option value="Pediatría">Pediatría</option>
        <option value="Psiquiatría">Psiquiatría</option>
        <option value="Rehabilitación">Rehabilitación</option>
        <option value="Reumatología">Reumatología</option>
        <option value="Toxicología">Toxicología</option>
        <option value="Urología">Urología</option>
      </select>
    </div>
  );
}

export default ComboEspecialidades;

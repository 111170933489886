import React, { useContext } from "react";
import { Link } from "react-router-dom";
import habilidadesAPI from "../../api/habilidadesAPI";
import newLogoHabilidades from "../../assets/images/newLogoHabilidades.png";
import AuthContext from "../../context/auth/authContext";
import { SET_PROFILE, SET_USER } from "../../context/types";

function CourseHeader() {
  const {
    authUser,
    profile: { nombre },
    dispatchAuth
  } = useContext(AuthContext);

  const cambio_ruta = (event) => {
    document.getElementById('navbarNavDropdown').classList.remove('show')
  }

  const doSignOut = async () => {
    try {
      dispatchAuth({
        type: SET_USER,
        payload: null
      })
      dispatchAuth({
        type: SET_PROFILE,
        payload: {}
      })
      await habilidadesAPI.post('/logout')

    } catch (error) {
      console.log('error from logout', error)
    }
    // history.push("/");
  };

  const authLinks = (
    <ul className="navbar-nav">
      <li className="nav-item mr-2">
        Bienvenid@ <strong>{authUser && nombre}</strong>{" "}
        <Link to="/profile" onClick={cambio_ruta}>
          {/* <Link onClick={() => history.push('profile')}> */}
          <i
            style={{ color: "#62aa68", cursor: "pointer", fontSize: "1.2rem" }}
            className="fa far fa-eye"
          ></i>
        </Link>
      </li>
      {/* <li className="nav-item">Hola {user && user[0].nombre}</li> */}
      <li className="nav-item">
        <button className="nav-link auth" onClick={doSignOut}>
          {/* <button onClick={logout} href="#!"> */}
          <i className="fa fa-sign-out"></i> Salir
        </button>
      </li>
    </ul>
  );

  const guestLinks = (
    <div className="ml-auto">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link auth" to="/register" onClick={cambio_ruta}>
            Registrarse
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link auth" to="/login" onClick={cambio_ruta}>
            Entrar
          </Link>
        </li>
      </ul>
    </div>
  );

  // if (!authUser) {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className="row">
      <div className="col">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/" onClick={cambio_ruta}>
            <img className="header-logo" src={newLogoHabilidades} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="menu-hamburguesa"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto mr-5">
              <li className="nav-item  active">
                <Link className="nav-link" to="presentacion" onClick={cambio_ruta}>
                  Presentación <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/coordinadores" onClick={cambio_ruta}>
                  Coordinadores
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/autores" onClick={cambio_ruta}>
                  Autoría
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/sumario" onClick={cambio_ruta}>
                  Sumario
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/video-page-by-category" onClick={cambio_ruta}>
                  Vídeos
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/pdf-page" onClick={cambio_ruta}>
                  PDFs
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/pdf-page-by-category" onClick={cambio_ruta}>
                  PDFs
                </Link>
              </li>
            </ul>
            {authUser ? authLinks : guestLinks}
          </div>
        </nav>
      </div>

      {/* <div className="col-4">
        <nav className="navbar-nav navbar-expand navbar-light">
          { authUser ? authLinks : guestLinks }
        </nav>

        <hr/>

      </div> */}
    </div>
  );
}

export default CourseHeader;

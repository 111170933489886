import React from "react";

function Presentacion({ backImage, withImage, setWithImage }) {
  return (
    <div className="row d-flex content justify-content-end react-transition fade-in">
      <div className="col-md-3"></div>
      <div className="col-md-9 presentacion">
        <h2>Presentación</h2>
        <p>
          La rápida evolución del conocimiento en la medicina actual y la
          innovación biotecnológica y farmacéutica requiere de una constante
          puesta al día de los profesionales médicos; esto es así para todas las
          disciplinas y especialidades de la Medicina, pero si hay una
          especialidad en la que esta actualización es especialmente complicada
          es, sin duda, la <strong>Atención Primaria,</strong> debido a lo
          extenso de los conocimientos que abarca.
        </p>

        <p>
          Además, en el quehacer diario del médico de familia se necesita tener
          actualizadas y entrenadas un amplio abanico de habilidades clínicas,
          por ello ponemos a tu alcance esta{" "}
          <strong>herramienta de consulta,</strong> disponible en soportes web y
          app.
        </p>

        <br />

          <h4>¡Regístrate y accede al contenido que necesitas!</h4>
      </div>
    </div>
  );
}

export default Presentacion;

import React, { useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import EditProfileField from "./EditProfileField";

function Profile({ handle, arrowClass, show }) {
  const { profile } = useContext(AuthContext);

  const {
    nombre,
    apellidos,
    especialidad,
    localidad,
    provincia,
  } = profile;

  return (
    <div className="row d-flex justify-content-end mb-5 mr-5 react-transition swipe-right">
      <div className="col-md-6 content-container">
        <div className="mb-3">
          <h2>Sobre mí:</h2>
        </div>
        <EditProfileField name="nombre" label="Nombre:" fieldValue={nombre} />
        <EditProfileField
          name="apellidos"
          label="Apellidos:"
          fieldValue={apellidos}
        />
        <EditProfileField
          name="especialidad"
          label="Especialidad:"
          fieldValue={especialidad}
        />
        <EditProfileField
          name="localidad"
          label="Localidad:"
          fieldValue={localidad}
        />
        <EditProfileField
          name="provincia"
          label="Provincia:"
          fieldValue={provincia}
        />
      </div>
    </div>
  );
}

export default Profile;

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import habilidadesAPI from "../../api/habilidadesAPI";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import { UPDATE_USER_FIELD } from "../../context/types";
import ComboEspecialidades from '../auth/ComboEspecialidades';
import ComboProvincias from '../auth/ComboProvincias';

function EditProfileField(props) {
  const alertContext = useContext(AlertContext);
  console.log(alertContext)
  const [editField, setEditField] = useState(false);

  const { setAlert } = alertContext;

  const { dispatchAuth } = useContext(AuthContext);

  const { register, handleSubmit, setError, errors } = useForm();

  const { name, label, fieldValue } = props;

  const sendUpdatedField = async field => {


    try {

      const res = await habilidadesAPI.post("/update-field", field);

      dispatchAuth({
        type: UPDATE_USER_FIELD,
        payload: res.data[0]
      })

    } catch (error) {
      console.log("error sending field", error);
      setError('server', 'field', 'Ha ocurrido un error al actualizar este dato.')
    }
  };

  const onSubmit = (data, e) => {
    sendUpdatedField(data);

    setEditField(false);
  };

  useEffect(() => {
    errors[`${name}`] && setAlert(errors[`${name}`].message, "danger")
  }, [errors])

  console.log('errors', errors)


  return (
    <div className="d-flex justify-content-between mb-3">
      {editField ? (
        <>
          <form
            className="d-flex justify-content-between  react-transition fade-in mb-3"
            onSubmit={handleSubmit(onSubmit)}
          >

            {name === 'especialidad' ? <ComboEspecialidades register={register} fieldValue={fieldValue} /> : name === 'provincia' ? <ComboProvincias register={register} /> : <><label
              style={{
                fontFamily: '"Open Sans", sans-serif',
                fontSize: ".9rem"
              }}
              htmlFor={name}
            >
              {label}
            </label>{" "}
              <input
                style={{ marginLeft: "4px" }}
                name={name}
                placeholder={fieldValue}
                type="text"
                ref={register({
                  required: "Debes rellenar este campo.",
                })}
              /></>}
            <button
              type="submit"
              style={{ padding: "0", background: "none", border: "none" }}
            >
              <i
                style={{
                  color: "#5aa5df",
                  marginLeft: "5px",
                  fontSize: "1.3rem"
                }}
                className="fa fas fa-paper-plane"
              ></i>
            </button>
          </form>
          <span
            onClick={() => setEditField(false)}
            style={{ fontSize: "1rem", cursor: "pointer" }}
          >
            <i
              style={{ color: "#62aa68" }}
              className="fa far fa-window-close"
            ></i>
          </span>
        </>
      ) : (
          <>
            <p
              className="card-text react-transition fade-in"
              style={{
                fontSize: "1.2rem",
                fontFamily: '"Open Sans", sans-serif'
              }}
            >
              <span
                style={{
                  fontSize: ".9rem",
                  fontFamily: '"Open Sans", sans-serif',
                  color: "#62aa68",
                  marginRight: "5px"
                }}
              >
                {label}
              </span>{" "}
              {fieldValue}
            </p>
            <span
              onClick={() => setEditField(true)}
              style={{ fontSize: "1.2rem", cursor: "pointer" }}
            >
              <i
                style={{ color: "#62aa68", fontSize: "1.1rem" }}
                className="fa far fa-edit"
              ></i>
            </span>
          </>
        )}
    </div>
  );
}

// Question.propTypes = {
//   content: PropTypes.string.isRequired
// };

export default EditProfileField;

import React, { useContext, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Home from "./components/Home";
import Autores from "./components/info/Autores";
import Coordinadores from "./components/info/Coordinadores";
import AvisoLegal from "./components/legales/AvisoLegal";
import Legales from "./components/legales/Legales";
import Pdf from "./components/pdf/Pdf";
import PdfPage from "./components/pdf/PdfPage";
import PdfPageByCategory from "./components/pdf/PdfPageByCategory";
import Presentacion from "./components/Presentacion";
import Profile from "./components/profile/Profile";
import PrivateRoute from "./components/routing/PrivateRoute";
import SumarioByCategory from "./components/sumario/SumarioByCategory";
import Tema from "./components/Tema";
import Alerts from "./components/template/Alerts";
import Footer from "./components/template/Footer";
import Header from "./components/template/Header";
import SubFooter from "./components/template/SubFooter";
import Video from "./components/Video";
import VideoPageByCategory from "./components/videos/VideoPageByCategory";
import AuthContext from "./context/auth/authContext";
import ContentState from "./context/content/ContentState";

function App() {
  const authContext = useContext(AuthContext);

  const withImageRef = useRef({ className: "App" });
  withImageRef.current = "App";
  const { relogin } = authContext;

  useEffect(() => {
    relogin()
    // eslint-disable-next-line
  }, [])

  return (

    <ContentState>
      <Router>
        {/* <Router basename={"/habilidades-website"}> */}
        <div className="App">
          <div className="container">
            <Header />
            <Alerts />
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/presentacion"
                render={() => <Presentacion />}
              />
              <Route exact path="/coordinadores" component={Coordinadores} />
              <Route exact path="/autores" component={Autores} />
              <PrivateRoute exact path="/tema/:temaId" component={Tema} />
              <PrivateRoute exact path="/pdf-page" component={PdfPage} />
              <Route exact path="/sumario" component={SumarioByCategory} />
              <PrivateRoute
                exact
                path="/pdf-page-by-category"
                component={PdfPageByCategory}
              />
              <PrivateRoute
                exact
                path="/video-page-by-category"
                component={VideoPageByCategory}
              />
              <PrivateRoute exact path="/pdf" component={Pdf} />
              <PrivateRoute exact path="/video/:videoId" component={Video} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/politica-de-privacidad"
                component={Legales}
              />
              <Route
                exact
                path="/aviso-legal"
                component={AvisoLegal}
              />
            </Switch>

            {/* <Ejemplo /> */}
            <SubFooter />
            <Footer />
          </div>
        </div>
      </Router>
    </ContentState>
  );
}

export default App;

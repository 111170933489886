import axios from "axios";
import React, { useEffect, useState } from "react";
import { categoriesIDs, inverseCategoriesIDs, inverseTemasIDs } from "../../api/categoriesId";
import wpAPI from "../../api/wpAPI";
import allPDFs from "../../assets/allPDFs";
import iconImages from "../../assets/images";
import Spinner from "../Spinner";

const PdfForCateg = React.memo(({ localCategoryId, pdfNum }) => {
  const [temaData, setTemaData] = useState([]);
  // eslint-disable-next-line
  const [categNum, setCategNum] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const categQuery = () => {
      // let requiredCateg = categoryId;
      let requiredCategId = categoriesIDs.get(localCategoryId);
      // setCategId(requiredCategId);

      const fetchData = async () => {
        try {
          const result = await wpAPI.get(`/categories/${requiredCategId}`, {
            cancelToken: source.token
          });
          const tema = await wpAPI.get(
            `/categories/?parent=${requiredCategId}`,
            {
              cancelToken: source.token
            }
          );

          // setCategData(result.data);
          setTemaData(tema.data);
          setCategNum(inverseCategoriesIDs.get(result.data.id));
        } catch (e) {
          if (axios.isCancel(e)) {
            console.log("canceled");
          } else {
            throw e;
          }
        }
      };
      fetchData();
    };

    async function fetchData() {
      await categQuery();
      setLoading(false);
    }
    fetchData();
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [localCategoryId]);

  return loading ? (
    <Spinner />
  ) : (
      <div
        style={{
          border: "none",
          boxShadow: "0 0 18px rgba(90, 165, 223, .3)",
          backgroundColor: "rgba(255, 255, 255, .2)"
        }}
        className="card mb-4"
      >
        <div
          style={{ backgroundColor: "rgba(255, 255, 255, .2)" }}
          className="card-header text-center"
        >
          <img
            style={{ width: "21%" }}
            src={iconImages[localCategoryId - 1]}
            alt=""
          />
          <h3>Sección {localCategoryId}</h3>
          <h5>Temas</h5>
        </div>

        <div
          style={{ backgroundColor: "rgba(78, 150, 84, .2)" }}
          className="card-body d-flex justify-content-center"
        >
          {temaData.map(tema => {
            const currentPdf = allPDFs[`PDF${inverseTemasIDs.get(tema.id)}`];
            return (
              <div key={tema.id} className="text-center mr-1 ml-1">
                <a target="blank" href={currentPdf}>
                  <i
                    style={{
                      fontSize: "1.8rem",
                      color: "#ec7206",
                      boxShadow: "0 0 11px rgba(255,255,255, 1)"
                    }}
                    className={`fa fa-file-pdf-o`}
                  ></i>
                </a>
                <p
                  className="mt-1"
                  style={{
                    fontSize: ".9rem",
                    fontWeight: "900"
                  }}
                >
                  {inverseTemasIDs.get(tema.id)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
});

export default PdfForCateg;

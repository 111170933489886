import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import habilidadesAPI from "../../api/habilidadesAPI";
import AuthContext from "../../context/auth/authContext";
import { SET_PROFILE, SET_USER } from "../../context/types";
import Spinner from "../Spinner";

/* eslint-disable no-unused-vars */
const Login = () => {
  const { register, handleSubmit, reset, errors, setError } = useForm({
    reValidateMode: "onChange",
    defaultValues: {},
    validationSchema: undefined, // Note: will be deprecated in the next major version with validationResolver
    validationResolver: undefined,
    validationContext: undefined,
    validateCriteriaMode: "firstErrorDetected",
    submitFocusError: true,
    nativeValidation: false
  });


  const { authUser, profile, dispatchAuth } = useContext(AuthContext);
  const [verification, setVerification] = useState("");
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    authUser && history.push("/");
    // eslint-disable-next-line
  }, [authUser]);

  const onSubmit = async (data, e) => {
    const { email, password } = data;
    try {
      setLoading(true)

      const res = await habilidadesAPI.post("/login", { email, password });


      dispatchAuth({
        type: SET_USER,
        payload: res.data.isAuth
      })

      const { isAuth, ...userToProfile } = res.data


      dispatchAuth({
        type: SET_PROFILE,
        payload: userToProfile
      })

      setLoading(() => false);
      history.push("/")



    } catch (error) {
      setLoading(false)
      setError("server", "server", error.response.data.msg)
    }
  };

  return (
    <div className="row d-flex justify-content-end d-flex mb-5 mt-5 mr-5">
      <div className="col-md-5">
        <h3>Introduce tus datos para identificarte</h3>
        <div className="form-container mb-3 mt-4">
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                ref={register({ required: true })}
              />
            </div>
            {errors.email && (
              <span className="error">Debes rellenar este campo</span>
            )}

            {/* include validation with required or other standard HTML validation rules */}
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <input
                name="password"
                type="password"
                ref={register({
                  required: {
                    value: true,
                    message: "Debes rellenar este campo"
                  },
                  minLength: {
                    value: 6,
                    message: 'Incluye al menos 6 caracteres.'
                  }
                })}
              />
              {/* errors will return when field validation fails  */}
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary btn-lg btn-block">Entrar</button>
            </div>
          </form>
          <div className="d-flex justify-content-around mt-3">
            <Link className="peq" to="/register">
              Aún no estoy registrado
            </Link>
            <Link className="peq" to="/forgot-password">
              Olvidé la contraseña
            </Link>
          </div>
        </div>
        {errors.server && <span className="error">{errors.server.message}</span>}
      </div>
      {loading && <Spinner />}
    </div>
  );
};
/* eslint-enable no-unused-vars */

export default Login;

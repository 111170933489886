import React from "react";

function ComboProvincias({ register }) {
  return (
    <div className="form-group">
      <label htmlFor="provincia">Provincia</label>
      <select
        className="form-control"
        name="provincia"
        ref={register({ required: true })}
      >
        <option>Álava</option>
        <option>Albacete</option>
        <option>Alicante</option>
        <option>Almería</option>
        <option>Ávila</option>
        <option>Badajoz</option>
        <option>Illes Balears</option>
        <option>Barcelona</option>
        <option>Burgos</option>
        <option>Cáceres</option>
        <option>Cádiz</option>
        <option>Castellón</option>
        <option>Ciudad Real</option>
        <option>Córdoba</option>
        <option>Coruña</option>
        <option>Cuenca</option>
        <option>Girona</option>
        <option>Granada</option>
        <option>Guadalajara</option>
        <option>Guipuzcoa</option>
        <option>Huelva</option>
        <option>Huesca</option>
        <option>Jaén</option>
        <option>León</option>
        <option>Lleida</option>
        <option>La Rioja</option>
        <option>Lugo</option>
        <option>Madrid</option>
        <option>Málaga</option>
        <option>Murcia</option>
        <option>Navarra</option>
        <option>Ourense</option>
        <option>Asturias</option>
        <option>Palencia</option>
        <option>Las Palmas</option>
        <option>Pontevedra</option>
        <option>Salamanca</option>
        <option>S.C. Tenerife</option>
        <option>Cantabria</option>
        <option>Segovia</option>
        <option>Sevilla</option>
        <option>Soria</option>
        <option>Tarragona</option>
        <option>Teruel</option>
        <option>Toledo</option>
        <option>Valencia</option>
        <option>Valladolid</option>
        <option>Vizcaya</option>
        <option>Zamora</option>
        <option>Zaragoza</option>
        <option>Ceuta</option>
        <option>Melilla</option>
      </select>

    </div>
  );
}

export default ComboProvincias;

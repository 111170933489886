import { NEXT, PREV, SET_CATEGORY_ID, SET_THEME, SET_THEMES } from "../types";

export default (state, action) => {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        categoryId: state.categoryId - 1
      };
    case NEXT:
      return {
        ...state,
        categoryId: state.categoryId + 1
      };
    case SET_CATEGORY_ID:
      return {
        ...state,
        categoryId: action.payload
      };
    case SET_THEMES:
      return {
        ...state,
        themes: action.payload
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
};

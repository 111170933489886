const videoUrls = [
  "",
  "https://player.vimeo.com/video/196589183",
  "https://player.vimeo.com/video/195956198",
  "https://player.vimeo.com/video/196580722",
  "https://player.vimeo.com/video/198053104",
  "https://player.vimeo.com/video/197506822",
  "https://player.vimeo.com/video/200963012",
  "https://player.vimeo.com/video/166944589",
  "https://player.vimeo.com/video/196694993",
  "https://player.vimeo.com/video/197881538",
  "https://player.vimeo.com/video/166944702",
  "https://player.vimeo.com/video/196768181",
  "https://player.vimeo.com/video/166945147",
  "https://player.vimeo.com/video/166945137",
  "https://player.vimeo.com/video/198191676",
  "https://player.vimeo.com/video/197169133",
  "https://player.vimeo.com/video/198206061",
  "https://player.vimeo.com/video/166945371",
  "https://player.vimeo.com/video/167391816",
  "https://player.vimeo.com/video/166945373",
  "https://player.vimeo.com/video/166946265",
  "https://player.vimeo.com/video/200985861",
  "https://player.vimeo.com/video/166946266",
  "https://player.vimeo.com/video/196693754",
  "https://player.vimeo.com/video/245739443",
  "https://player.vimeo.com/video/245738374",
  "https://player.vimeo.com/video/245737359",
  "https://player.vimeo.com/video/307056769",
  "https://player.vimeo.com/video/303024721",
  "https://player.vimeo.com/video/306802935"
];

export default videoUrls;

import React from "react";

function Arrow({ handle, arrowClass, show }) {
  return (
    <div className="row" style={{ visibility: show }}>
      <div className="col">
        <div onClick={handle} className={arrowClass}>
          <div className={`${arrowClass}-top`}></div>
          <div className={`${arrowClass}-bottom`}></div>
        </div>
      </div>
    </div>
  );
}

export default Arrow;

const iconImages = [
  require("./images/rueda/1.png"),
  require("./images/rueda/2.png"),
  require("./images/rueda/3.png"),
  require("./images/rueda/4.png"),
  require("./images/rueda/5.png"),
  require("./images/rueda/6.png"),
  require("./images/rueda/7.png"),
  require("./images/rueda/8.png"),
  require("./images/rueda/9.png"),
  require("./images/rueda/10.png"),
  require("./images/rueda/11.png"),
  require("./images/rueda/12.png"),
  require("./images/rueda/13.png"),
  require("./images/rueda/14.png"),
  require("./images/rueda/15.png")
];

export default iconImages;

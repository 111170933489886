import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { categoriesIDs, inverseCategoriesIDs, inverseTemasIDs } from "../../api/categoriesId";
import wpAPI from "../../api/wpAPI";
import allPDFs from "../../assets/allPDFs";
import iconImages from "../../assets/images";
import AuthContext from "../../context/auth/authContext";
import ContentContext from "../../context/content/contentContext";
import { SET_CATEGORY_ID } from "../../context/types";
import ArrowToTop from "../common/ArrowToTop";
import Spinner from "../Spinner";

/* eslint-disable no-unused-vars */
function SumarioCard(props) {
  const [temaData, setTemaData] = useState([]);
  const [categNum, setCategNum] = useState(1);
  const [canceled, setCanceled] = useState(false);
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  let { localCategoryId, } = props;

  const contentContext = useContext(ContentContext);
  const { categoryId, dispatch } = contentContext;

  const [authors, setAuthors] = useState([]);
  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_CATEGORY_ID,
      payload: 1
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const categQuery = () => {
      // let requiredCateg = categoryId;
      let requiredCategId = categoriesIDs.get(localCategoryId);
      // setCategId(requiredCategId);

      const fetchData = async () => {
        try {
          setLoading(true);
          const result = await wpAPI.get(`/categories/${requiredCategId}`, {
            cancelToken: source.token
          });
          const tema = await wpAPI.get(
            `/categories/?parent=${requiredCategId}`,
            {
              cancelToken: source.token
            }
          );

          // setCategData(result.data);
          await setTemaData(tema.data);

          setCategNum(inverseCategoriesIDs.get(result.data.id));
          setLoading(false);
        } catch (e) {
          if (axios.isCancel(e)) {
            setCanceled(true);
          } else {
            throw e;
          }
        }
      };
      fetchData();
    };

    async function fetchData() {

      await categQuery();

    }
    fetchData();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [localCategoryId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const result = await wpAPI.get(`/pages?categories=46&per_page=30`, {
          cancelToken: source.token
        });

        await setAuthors(
          result.data.sort((x, y) => (x.slug > y.slug ? 1 : -1))
        );
        setColumn1(
          result.data
            .sort((x, y) => (x.title.rendered > y.title.rendered ? 1 : -1))
            .slice(0, Math.ceil(result.data.length / 2))
        );
        setColumn2(
          result.data
            .sort((x, y) => (x.title.rendered > y.title.rendered ? 1 : -1))
            .slice(Math.ceil(result.data.length / 2), result.data.length)
        );
      } catch (e) {
        if (axios.isCancel(e)) {
          setCanceled(true);
        } else {
          throw e;
        }
      }
    };
    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const handleCategory = categ => {
    dispatch({
      type: SET_CATEGORY_ID,
      payload: categ
    });
  };

  return !loading ? (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0)",
        border: "none",
        boxShadow: "0 0 11px rgba(255,255,255, 1)"
      }}
      className="card"
    >
      <div
        style={{ backgroundColor: "rgba(255, 255, 255, .3)" }}
        className="card-header d-flex justify-content-between align-items-center"
        id={`heading${localCategoryId}`}
      >
        <div className="d-flex align-items-center">
          <img
            style={{ width: "12%", marginRight: "7px" }}
            src={iconImages[localCategoryId - 1]}
            alt={`icon${localCategoryId}`}
          />
          <h3 style={{ margin: "0" }}>Sección {localCategoryId}</h3>
        </div>

        <span
          onClick={() => handleCategory(localCategoryId)}
          className="sumario-go-to-down"
          data-toggle="collapse"
          data-target={`#collapse${localCategoryId}`}
          aria-expanded={localCategoryId === 1 ? true : false}
          aria-controls={`collapse${localCategoryId}`}
        >
          <i className="fa fa-angle-down"></i>
        </span>
      </div>

      <div
        id={`collapse${localCategoryId}`}
        className={`collapse ${localCategoryId === 1 ? "show" : ""}`}
        aria-labelledby={`heading${localCategoryId}`}
        data-parent="#sumarioAccordion"
      >
        <div
          style={{ backgroundColor: "rgba(255, 255, 255, .5)" }}
          className="card-body"
        >
          {temaData.map((tema, i) => {
            const author =
              authors &&
              authors.filter(author =>
                author.title.rendered.includes(tema.name)
              )[0];

            const currentAuthor = author && author.content.rendered;

            const currentPdf = allPDFs[`PDF${inverseTemasIDs.get(tema.id)}`];
            return (
              <div
                key={tema.id}
                className="d-flex justify-content-between align-items-first mb-4 card-body-sumario"
              >
                <div className="sumario-left-block">
                  <h5>
                    <span
                      style={{ fontSize: "1rem", marginRight: "4px" }}
                      className="num-tema"
                    >
                      {tema.name}
                    </span>{" "}
                    <strong>{tema.description}</strong>
                  </h5>
                  <div className="autores-sumario-container">
                    <h3>Autores:</h3>
                    <div
                      key={i}
                      className="autores-sumario"
                      dangerouslySetInnerHTML={{
                        __html: currentAuthor
                      }}
                    />
                  </div>
                </div>

                {authUser ? (
                  <div>
                    <div className="otros-soportes-sumario">
                      <Link to={`./tema/${inverseTemasIDs.get(tema.id)}`}>
                        <i
                          style={{ fontSize: "1.2rem" }}
                          className={`fa fa-desktop`}
                        ></i>
                      </Link>
                    </div>
                    <div className="otros-soportes-sumario">
                      <Link
                        to={{
                          pathname: `../video/${inverseTemasIDs.get(tema.id)}`,
                          state: { tema: tema.description }
                        }}
                      >
                        <i
                          style={{ fontSize: "1.2rem" }}
                          className={`fa fa-file-video-o`}
                        ></i>
                      </Link>
                    </div>
                    <div className="otros-soportes-sumario">
                      <a target="blank" href={currentPdf}>
                        <i
                          style={{ fontSize: "1.2rem" }}
                          className={`fa fa-file-pdf-o`}
                        ></i>
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <ArrowToTop />
    </div>
  ) : (
      <Spinner />
    );
}
/* eslint-enable no-unused-vars */
export default SumarioCard;

import React from "react";
import Politicas from './Politicas';

function Legales() {
  return (
    <div className="row content content-container legales react-transition fade-in mt-5">
      <div className="col">
        <h3>POLÍTICA DE PRIVACIDAD</h3>
        <Politicas />
      </div>
    </div>
  );
}

export default Legales;

const numberOfItems = 15;

const iconWidth = 56;
const iconRadius = iconWidth / 2;

const wheelWidth = 550;
const wheelRadius = wheelWidth / 2;
const radius2 = wheelRadius - iconRadius;
const alpha = Math.PI / 2;
const corner = (2 * Math.PI) / numberOfItems;

const degBetweenIcons = parseInt(alpha * numberOfItems) + 1;

const iconCircles = Array.from({ length: numberOfItems });

export {
  numberOfItems,
  iconWidth,
  iconRadius,
  wheelWidth,
  wheelRadius,
  radius2,
  alpha,
  corner,
  degBetweenIcons,
  iconCircles
};

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { categoriesIDs, inverseCategoriesIDs, inverseTemasIDs } from "../../api/categoriesId";
import wpAPI from "../../api/wpAPI";
import iconImages from "../../assets/images";
import ContentContext from "../../context/content/contentContext";
import Spinner from "../Spinner";

const Tooltip = () => {
  const contentContext = useContext(ContentContext);

  const { categoryId } = contentContext;
  const [temaData, setTemaData] = useState([]);
  const [categNum, setCategNum] = useState(1);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const categQuery = () => {
      // let requiredCateg = categoryId;
      let requiredCategId = categoriesIDs.get(categoryId);
      // setCategId(requiredCategId);

      const fetchData = async () => {
        try {
          const result = await wpAPI.get(`/categories/${requiredCategId}`, {
            cancelToken: source.token
          });
          const tema = await wpAPI.get(
            `/categories/?parent=${requiredCategId}`,
            {
              cancelToken: source.token
            }
          );

          setTemaData(tema.data);

          // setCategData(result.data);
          setCategNum(inverseCategoriesIDs.get(result.data.id));
          await setTemaData(tema.data);
        } catch (e) {
          if (axios.isCancel(e)) {
            console.log("canceled");
          } else {
            throw e;
          }
        }
      };
      fetchData();
    };

    async function fetchData() {
      await categQuery();
    }
    fetchData();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [categoryId]);

  return (
    <div className={"toolt"}>
      <img src={iconImages[categoryId - 1]} alt="" />
      {/* <span className={"tooltiptext"}>
        <h5>content</h5>
      </span> */}
      <div className="modal-dialog tooltiptext" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Sección: {categNum}
            </h5>
          </div>
          <div className="modal-body">
            {temaData.length === 0 ? (
              <Spinner />
            ) : (
                <>
                  {temaData.map((tema, i) => {
                    return (
                      <Link
                        className="subnav"
                        key={tema.id}
                        to={`./${inverseTemasIDs.get(tema.id)}`}
                      >
                        <p key={tema.name}>
                          {tema.name}. <strong>{tema.description}</strong>
                        </p>
                      </Link>
                    );
                  })}
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;

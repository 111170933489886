import React, { useState } from "react";
import Spinner from "../Spinner";
import Pdf from "./Pdf";

function PdfPage() {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  return loading ? (
    <Spinner />
  ) : (
      <div className="row ">
        <div className="col">
          <div className="row">
            <div className="col">
              <h2>PDF Page</h2>
            </div>
          </div>

          <div className="row content-container">
            {Array.from({ length: 29 }).map((el, i) => (
              <div key={i} className="col-md-3">
                <Pdf pdfNum={i + 1} />
              </div>
            ))}
          </div>

          <div className="row content-container">
            {Array.from({ length: 15 }).map((el, i) => (
              <div key={i} className="col-md-3">
                <Pdf pdfNum={i + 1} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default PdfPage;

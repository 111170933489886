import React, { useContext } from "react";
import iconImages from "../../assets/images";
import fondoRueda from "../../assets/images/rueda/fondoRueda.png";
import { alpha, corner, degBetweenIcons, iconRadius, iconWidth, numberOfItems, radius2, wheelRadius, wheelWidth } from "../../constants/circleDefinition";
import ContentContext from "../../context/content/contentContext";

let currentAlpha = alpha;

const iconCircles = Array.from({ length: numberOfItems });

const Wheel = React.memo(({ degsValue }) => {

  const contentContext = useContext(ContentContext);

  const { categoryId } = contentContext;

  const styles = {
    wheel: {
      width: wheelWidth,
      height: wheelWidth,
      borderRadius: wheelRadius,
      backgroundColor: "rgba(255,255,255,.5)",
      backgroundImage: `url(${fondoRueda})`,
      backgroundSize: "87%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "relative",
      boxShadow: "0 0 80px #fff",
      overflow: "hidden",
      transform: `rotate(${(categoryId - 2) * -1 * degBetweenIcons}deg)`,
      transition: "transform 1s"
    },
    iconContainer: {
      width: iconWidth,
      height: iconWidth,
      position: "absolute",
      borderRadius: iconRadius,
      boxShadow: "0 0 30px #fff",
      transition: "width 1s, height 1s, box-shadow 1s",
      top: "0"
    },
    greatIconContainer: {
      width: iconWidth + 20,
      height: iconWidth + 20,
      position: "absolute",
      borderRadius: iconRadius
    },
    iconImage: {
      width: "100%",
      height: "100%"
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="rotation" style={styles.wheel}>
        {iconCircles.map((_, i) => {
          currentAlpha = currentAlpha - corner;
          return (
            <div
              style={{
                ...styles.iconContainer,
                left: parseFloat(
                  wheelRadius -
                  iconRadius +
                  (radius2 - 26) * Math.cos(currentAlpha + 0.85)
                ),
                top: parseFloat(
                  -1 *
                  (wheelRadius +
                    iconRadius +
                    (radius2 - 26) * Math.sin(currentAlpha + 0.85)) +
                  wheelWidth
                )
              }}
              key={i}
            >
              <img
                style={{
                  ...styles.iconImage,
                  transform: `rotate(${degBetweenIcons * (i - 1)}deg)`
                }}
                src={iconImages[i]}
                alt={`icono ${i + 1}`}
              />
            </div>
          );
        })}
      </div>
      <div className="section-num">{categoryId}</div>
    </div>
  );
});

export default Wheel;

import React, { useReducer, useState } from "react";
import habilidadesAPI from "../../api/habilidadesAPI";
import { SET_PROFILE, SET_USER } from "../types";
import AuthContext from "./authContext";
import authReducer from "./authReducer";

const AuthState = props => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const initialState = {
    authUser: null,
    profile: {},
  };

  const [state, dispatchAuth] = useReducer(authReducer, initialState);

  const relogin = async () => {
    try {
      setLoading(true)

      const res = await habilidadesAPI.post("/relogin-user");

      dispatchAuth({
        type: SET_USER,
        payload: res.data.isAuth
      })

      const { isAuth, ...userToProfile } = res.data


      dispatchAuth({
        type: SET_PROFILE,
        payload: userToProfile
      })

      setLoading(false)

    } catch (error) {
      console.log('error from relogin', error)
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authUser: state.authUser,
        profile: state.profile,
        dispatchAuth,
        relogin
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

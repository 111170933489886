import React from "react";

function Spinner() {
  return (
    <div className="row mt-5 mb-5 d-flex">
      <div
        className="ml-auto mr-auto spinner-grow text-secondary"
        role="status"
      >
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  );
}

export default Spinner;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { categoriesIDs, inverseCategoriesIDs, inverseTemasIDs } from "../api/categoriesId";
import wpAPI from "../api/wpAPI";
import ContentContext from "../context/content/contentContext";
import { NEXT, PREV } from "../context/types";
import Arrow from "./Arrow";
import "./home.css";
import Spinner from "./Spinner";
import Wheel from "./wheel/Wheel";

/* eslint-disable no-unused-vars */
function Home() {
  const contentContext = useContext(ContentContext);

  const [temaData, setTemaData] = useState([]);
  const [categNum, setCategNum] = useState(1);
  const [arrowTop, setArrowTop] = useState({});
  const [pointing, setPointing] = useState("");

  const { categoryId, dispatch } = contentContext;

  useEffect(() => {
    categQuery();
    // eslint-disable-next-line
  }, [categoryId]);

  const categQuery = () => {
    // let requiredCateg = categoryId;
    let requiredCategId = categoriesIDs.get(categoryId);
    // setCategId(requiredCategId);

    const fetchData = async () => {
      try {
        const result = await wpAPI.get(`/categories/${requiredCategId}`);
        const tema = await wpAPI.get(`/categories/?parent=${requiredCategId}`);

        // setCategData(result.data);
        setTemaData(tema.data);
        setCategNum(inverseCategoriesIDs.get(result.data.id));
      } catch (e) { }
    };
    fetchData();
  };

  const handlePointing = () => {
    setPointing("pointing-arrow");
    setTimeout(() => setPointing(""), 800);
  };

  const handlePrev = e => {
    if (categoryId > -15) {
      dispatch({
        type: PREV
      });
    }
    handlePointing();
  };

  const handleNext = e => {
    if (categoryId < 15) {
      dispatch({
        type: NEXT
      });
    }
    handlePointing();
  };

  const arrowTopStyle = {
    marginBottom: "-25px",
    color: "red"
  };

  return (
    <div className="home transition-container react-transition fade-in">
      <div className="row">
        <div className="col-md-4 pt-4 d-flex flex-column">
          <div className="accede">
            <h3>
              Accede a una de las más prestigiosas herramientas de conocimiento
              para el médico de Atención Primaria
            </h3>
          </div>
          <div className="row mt-auto sections">
            <div className="col">
              {temaData.length === 0 ? (
                <Spinner />
              ) : (
                  <>
                    <h3>Sección: {categNum}</h3>
                    {temaData.map((tema, i) => {
                      return (
                        <Link
                          key={tema.id}
                          to={`./tema/${inverseTemasIDs.get(tema.id)}`}
                        >
                          <p>
                            {tema.name}. <strong>{tema.description}</strong>
                          </p>
                        </Link>
                      );
                    })}

                    <div className="arrows-sm-screen">

                      <button show={categoryId !== 1 ? "visible" : "hidden"} onClick={handlePrev} className='arrow-top-peq'>Atrás</button>
                      <button show={categoryId !== 15 ? "visible" : "hidden"} onClick={handleNext} className='arrow-bottom-peq'>Adelante</button>

                    </div>

                  </>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-8 wheel">
          <div style={arrowTopStyle} className="row">
            <div className="col d-flex justify-content-center">
              <i
                style={{ fontSize: "3rem", color: "#62aa68" }}
                className={`fa fas fa-angle-down ${pointing}`}
              // class="fa fas fa-angle-down pointing-arrow"
              ></i>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-between align-items-end">
              <Arrow
                handle={handlePrev}
                arrowClass="arrow2"
                show={categoryId !== 1 ? "visible" : "hidden"}
              />

              <Wheel />

              <Arrow
                handle={handleNext}
                arrowClass="arrow"
                show={categoryId !== 15 ? "visible" : "hidden"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-enable no-unused-vars */
export default Home;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { categoriesIDs, inverseTemasIDs, temasIDs } from "../api/categoriesId";
import wpAPI from "../api/wpAPI";
import allPDFs from "../assets/allPDFs";
import ContentContext from "../context/content/contentContext";
import ArrowToTop from "./common/ArrowToTop";
import Tooltip from "./common/Tooltip";
import Spinner from "./Spinner";

function Tema(props) {
  const contentContext = useContext(ContentContext);

  const { categoryId } = contentContext;

  const [temaData, setTemaData] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const [authors, setAuthors] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [activeItem, setActiveItem] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    categQuery();
    // eslint-disable-next-line
  }, [categoryId, props.match.params.temaId]);


  const categQuery = () => {
    let requiredCategId = categoriesIDs.get(categoryId);
    let requiredTemaId = temasIDs.get(parseInt(props.match.params.temaId));

    const fetchData = async () => {
      try {
        const result = await wpAPI.get(
          `/pages?categories=${requiredCategId}&categories=${requiredTemaId}&per_page=25&password=cg1234`
        );
        const subcategResult = await wpAPI.get(
          `/categories/?parent=${requiredCategId}`
        );

        const resultDataWithotAuthor = result.data.slice(1);

        setAllPages(resultDataWithotAuthor.reverse());

        setAuthors(result.data[0]);

        setTemaData(subcategResult.data);
      } catch (e) { }
    };
    fetchData();
  };

  let filtrado = temaData.filter(
    (el, id) =>
      inverseTemasIDs.get(el.id) === parseInt(props.match.params.temaId)
  );
  let temaNum = filtrado[0] && filtrado[0].name;
  let temaTitle = filtrado[0] && filtrado[0].description;

  let numOfPages = allPages.length - 1;

  let preIntroTitle = allPages[0] && allPages[0].title.rendered;
  let preIntroContent = allPages[0] && allPages[0].content.rendered;

  let autores = authors && authors.content.rendered;

  const goToNextPage = () => {
    let nextPageIndex = pageIndex + 1;
    setTitle(allPages[nextPageIndex].title.rendered);
    setContent(allPages[nextPageIndex].content.rendered);
    setActiveItem(nextPageIndex);
    setPageIndex(nextPageIndex);
  };

  const goToPrevtPage = () => {
    let nextPageIndex = pageIndex - 1;
    setTitle(allPages[nextPageIndex].title.rendered);
    setContent(allPages[nextPageIndex].content.rendered);
    setActiveItem(nextPageIndex);
    setPageIndex(nextPageIndex);
  };

  const currentPdf = allPDFs[`PDF${props.match.params.temaId}`];

  return preIntroContent && preIntroTitle && allPages ? (
    <>
      <div className="content-container tema mt-5 transition-container react-transition fade-in">
        <div className="row tema">
          <div className="col-md-6">
            <div className="row">
              <div className="col">
                <div className="title-asignatura">
                  <Tooltip />
                  <h4>Sección: {categoryId}</h4>

                  <h5 className="num-tema">{temaNum}</h5>
                  <h3>
                    <strong>{temaTitle}</strong>
                  </h3>
                </div>
                <div
                  className="autores-en-tema react-transition fade-in"
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: autores || preIntroContent
                  }}
                />
                <div className="otros-soportes">
                  <Link
                    to={{
                      pathname: `../video/${props.match.params.temaId}`,
                      state: { tema: temaTitle }
                    }}
                  >
                    <i
                      style={{ fontSize: "1.5rem", color: "#ec7206" }}
                      className={`fa fa-file-video-o`}
                    ></i>
                  </Link>
                </div>
                <div className="otros-soportes">
                  <a target="blank" href={currentPdf}>
                    <i
                      style={{ fontSize: "1.5rem", color: "#ec7206" }}
                      className={`fa fa-file-pdf-o`}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col">
                <nav className="nav flex-column">
                  <h3>Contenido</h3>
                  {allPages.map((page, i, arr) => {
                    const {
                      content,
                      title,
                      acf: { number }
                    } = page;

                    return (
                      <button
                        className={`nav-link ${
                          activeItem === i ? "active" : ""
                          }`}
                        key={i}
                        onClick={e => {
                          setContent(content.rendered);
                          setTitle(title.rendered);
                          setActiveItem(i);
                          setPageIndex(i);
                        }}
                      >
                        {number === "0" || number === `${numOfPages}`
                          ? title.rendered
                          : `${number}. ${title.rendered}`}
                      </button>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="row content">
          <div className="col">
            <h1>{title || preIntroTitle}</h1>
            <div
              className="react-transition fade-in"
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{ __html: content || preIntroContent }}
            />
          </div>
        </div>

        <div className="botton-navigation d-flex justify-content-center">
          {pageIndex > 0 ? (
            <div className="nav-left d-flex justify-content-start align-items-center mr-2">
              <a onClick={goToPrevtPage}>
                <i className={`fa fas fa-angle-left`}></i>
              </a>
              <p>Anterior</p>
            </div>
          ) : null}
          {pageIndex < allPages.length - 1 ? (
            <div className="nav-right d-flex justify-content-end align-items-center ml-2">
              <p>Siguiente</p>
              <a onClick={goToNextPage}>
                <i className={`fa fas fa-angle-right`}></i>
              </a>
            </div>
          ) : null}
        </div>

        <ArrowToTop />
      </div>
    </>
  ) : (
      <Spinner />
    );
}

export default Tema;

import React from "react";
import appleStore from "../../assets/images/appstore-es.png";
import googlePlay from "../../assets/images/googleplay-es.png";
import logoHeel from "../../assets/images/logos/logo-heel.png";
import logoSemergen from "../../assets/images/logos/logo-semergen.png";

function SubFooter() {
  return (
    <>
      <div className="row logos mt-4 d-flex justify-content-between align-items-end sub-footer">
        <div className="text-center col-md-3">
          <p style={{ fontSize: ".8rem", marginBottom: "6px" }}>
            Con la colaboración de:
          </p>
          <a target="blank" href="https://www.heel.es/es/home.html">
            <img
              style={{ width: "37%", justifySelf: "center" }}
              src={logoHeel}
              alt=""
            />
          </a>
        </div>
        <div className="d-flex justify-content-around col-md-3">
          <div className="text-center">
            <a href='https://play.google.com/store/apps/details?id=com.habil.habilidades' target='_blank' style={{ cursor: "pointer" }}>
              <img style={{ width: "70%" }} src={googlePlay} alt="" />
            </a>
          </div>
          <div className="text-center">
            <a href='https://apps.apple.com/us/app/habilidades-clinicas/id1502160206?ls=1' target='_blank' style={{ cursor: "pointer" }}>
              <img style={{ width: "141%" }} src={appleStore} alt="" />
            </a>
          </div>
        </div>
        <div className="text-center col-md-3">
          <a target="blank" href="https://www.semergen.es/">
            <img
              className="logo-semergen"
              style={{ width: "79%" }}
              src={logoSemergen}
              alt=""
            />
          </a>
        </div>
      </div>
      <hr />
    </>
  );
}

export default SubFooter;

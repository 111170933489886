import React, { useState } from "react";
import { useForm } from "react-hook-form";
import habilidadesAPI from "../../api/habilidadesAPI";
import Spinner from "../Spinner";


const ForgotPassword = () => {

  const { register, handleSubmit, errors, setError } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)

      // eslint-disable-next-line
      const res = await habilidadesAPI.post("/email-to-change-password", data);

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError("server", "server", error.response.data.msg)
    }
  }

  return (
    <div className="row d-flex justify-content-end d-flex mb-5 mt-5 mr-5">
      <div className="col-md-6">
        <h3>Olvidé la contraseña</h3>
        <div className="form-container mb-3 mt-4">
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input name="email" type="email" ref={register({ required: true })} />
              {/* errors will return when field validation fails  */}
              {errors.email && <span className="error">Debes rellenar este campo</span>}
            </div>

            <input className="btn btn-primary" type="submit" />
          </form>
        </div>
        {errors.server && <span className="error">{errors.server.message}</span>}
      </div>
      {loading && <Spinner />}
    </div>
  )
};

export default ForgotPassword;

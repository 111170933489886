import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

function PrivateRoute({ component: Component, ...rest }) {
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  return (
    // <Route { ...rest } render={props => !authUser && !loading ? (
    <Route
      {...rest}
      render={props =>
        !authUser ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
}

export default PrivateRoute;

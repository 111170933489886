const allPDFs = {
  PDF1: require("./Habilidades_pdf/Tema-1.pdf"),
  PDF2: require("./Habilidades_pdf/Tema-2.pdf"),
  PDF3: require("./Habilidades_pdf/Tema-3.pdf"),
  PDF4: require("./Habilidades_pdf/Tema-4.pdf"),
  PDF5: require("./Habilidades_pdf/Tema-5.pdf"),
  PDF6: require("./Habilidades_pdf/Tema-6.pdf"),
  PDF7: require("./Habilidades_pdf/Tema-7.pdf"),
  PDF8: require("./Habilidades_pdf/Tema-8.pdf"),
  PDF9: require("./Habilidades_pdf/Tema-9.pdf"),
  PDF10: require("./Habilidades_pdf/Tema-10.pdf"),
  PDF11: require("./Habilidades_pdf/Tema-11.pdf"),
  PDF12: require("./Habilidades_pdf/Tema-12.pdf"),
  PDF13: require("./Habilidades_pdf/Tema-13.pdf"),
  PDF14: require("./Habilidades_pdf/Tema-14.pdf"),
  PDF15: require("./Habilidades_pdf/Tema-15.pdf"),
  PDF16: require("./Habilidades_pdf/Tema-16.pdf"),
  PDF17: require("./Habilidades_pdf/Tema-17.pdf"),
  PDF18: require("./Habilidades_pdf/Tema-18.pdf"),
  PDF19: require("./Habilidades_pdf/Tema-19.pdf"),
  PDF20: require("./Habilidades_pdf/Tema-20.pdf"),
  PDF21: require("./Habilidades_pdf/Tema-21.pdf"),
  PDF22: require("./Habilidades_pdf/Tema-22.pdf"),
  PDF23: require("./Habilidades_pdf/Tema-23.pdf"),
  PDF24: require("./Habilidades_pdf/Tema-24.pdf"),
  PDF25: require("./Habilidades_pdf/Tema-25.pdf"),
  PDF26: require("./Habilidades_pdf/Tema-26.pdf"),
  PDF27: require("./Habilidades_pdf/Tema-27.pdf"),
  PDF28: require("./Habilidades_pdf/Tema-28.pdf"),
  PDF29: require("./Habilidades_pdf/Tema-29.pdf")
};

export default allPDFs;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import habilidadesAPI from "../../api/habilidadesAPI";
import Spinner from "../Spinner";
import ComboEspecialidades from './ComboEspecialidades';
import ComboProvincias from './ComboProvincias';
import ModalPoliticas from './ModalPoliticas';

/* eslint-disable no-unused-vars */
const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    errors,
    formState,
    data,
    setError,
    clearError
  } = useForm();
  const [successRegistered, setSuccessRegistered] = useState("");
  const [selectBox, setSelectBox] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    successRegistered && reset()
    // eslint-disable-next-line
  }, [successRegistered])

  const onSubmit = async (data, e) => {
    // e.target.reset();
    try {
      setLoading(true)
      const res = await habilidadesAPI.post("/register", data);
      setSuccessRegistered(
        "Te has registrado con éxito. Recuerda verificar tu email mediante el enlace que te hemos enviado."
      );
      setLoading(false)

    } catch (error) {

      setError("server", "server", error.response.data.msg);
    }
  };

  return (
    <>
      <div className="row d-flex justify-content-end d-flex mb-5 mt-5 mr-5">
        <div className="col-md-8">
          <h3>Introduce tus datos para crear una cuenta</h3>
          <div className="form-container mb-3 mt-4">
            {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      name="nombre"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.nombre && (
                      <span className="error">Debes rellenar este campo</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="apellidos">Apellidos</label>
                    <input
                      name="apellidos"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.apellidos && (
                      <span className="error">Debes rellenar este campo</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      type="email"
                      ref={register({ required: true })}
                    />
                    {errors.email &&
                      errors.email.type &&
                      errors.email.type === "required" && (
                        <span className="error">Debes rellenar este campo</span>
                      )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Contraseña</label>
                    <input
                      name="password"
                      type="password"
                      ref={register({ minLength: 6 })}
                    />
                    {errors.password && (
                      <span className="error">
                        Introduce al menos 6 caracteres
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <ComboEspecialidades register={register} />
                  <div className="form-group">
                    <label htmlFor="localidad">Localidad</label>
                    <input
                      name="localidad"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.localidad && (
                      <span className="error">Debes rellenar este campo</span>
                    )}
                  </div>

                  <ComboProvincias register={register} />


                  {/* <div className="form-group">
                  <label htmlFor="provincia">Provincia</label>
                  <input
                    name="provincia"
                    type="text"
                    ref={register({ required: true })}
                  />
                  {errors.provincia && <span>Debes rellenar este campo</span>}
                </div> */}
                  <div className="form-group d-flex flex-column align-items-center justify-content-center">
                    <label htmlFor="confirmaprof">
                      <strong>Sí,</strong> soy profesional de la sanidad
                  </label>
                    <input
                      name="confirmaprof"
                      type="checkbox"
                      ref={register({ required: true })}
                    />
                    {errors.confirmaprof && (
                      <span className="error">Debes rellenar este campo</span>
                    )}
                  </div>
                </div>

                <div className="row proteccion-datos p-3" >
                  <div className="col">
                    <h5>INFORMACIÓN PROTECCIÓN DE DATOS DE INTERNATIONAL MARKETING & COMMUNICATION</h5>
                    <p><strong>Finalidades:</strong> Darle de alta como usuario a los servicios; remitirle información comercial por medios electrónicos de nuestros productos y servicios y de nuestros patrocinadores. <strong>Legitimación:</strong> Consentimiento del interesado e interés legítimo en mantener informados a nuestros usuarios registrados. <strong>Destinatarios:</strong> Las sociedades patrocinadoras  y avalistas que figuran en la página web. <strong>Derechos:</strong> Puede retirar su consentimiento en cualquier momento, así como acceder, rectificar, suprimir sus datos y demás derechos en faqs@habilidades-clinicas.com. <strong>Información adicional:</strong> Puede ampliar la información en el enlace de <span style={{ color: '#268268' }} type="button" className="" data-toggle="modal" data-target="#politicas"><strong>Política de Privacidad.</strong></span></p>

                    <div className='acepto-comercial'>
                      <input
                        name="confirmacomercial"
                        type="checkbox"
                        ref={register({ required: false })}
                      />
                      <label htmlFor="confirmacomercial">
                        Acepto recibir información comercial de las empresas patrocinadoras y avalistas.
                  </label>
                    </div>
                    <div className='acepto-politica'>
                      <input
                        name="confirmapolitica"
                        type="checkbox"
                        ref={register({ required: true })}
                      />
                      <label htmlFor="confirmapolitica">
                        Acepto la política de privacidad
                  </label>
                      {errors.confirmapolitica && (
                        <span className="error">Debes rellenar este campo</span>
                      )}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-lg btn-block">Registrarse</button>
              </div>



            </form>
            {console.log("errors", errors.server && errors.server.message)}
            {errors.server && <span className="error">{errors.server.message}</span>}
            {successRegistered ? (
              <span className="success-registered">{successRegistered}</span>
            ) : null}
            {/* {console.log("errors", errors.server && errors.server.message)}
          {errors.server && <span className="error">{errors.server.message}</span>} */}
          </div>

          {formState.isSubmitting && <Spinner />}
          {/* <Link className="peq" to="/register">
          Aún no estoy registrado
        </Link> */}

        </div>
      </div>
      <ModalPoliticas />
    </>
  );
};
/* eslint-enable no-unused-vars */
export default Register;

import React, { useEffect, useState } from "react";
import wpAPI from "../../api/wpAPI";
import ArrowToTop from "../common/ArrowToTop";
import Spinner from "../Spinner";

function Autores() {
  // eslint-disable-next-line
  const [authors, setAuthors] = useState([]);
  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await wpAPI.get(`/pages?categories=46&per_page=30`);

        await setAuthors(
          result.data.sort((x, y) => (x.slug > y.slug ? 1 : -1))
        );
        setColumn1(
          result.data
            .sort((x, y) => (x.title.rendered > y.title.rendered ? 1 : -1))
            .slice(0, Math.ceil(result.data.length / 2))
        );
        setColumn2(
          result.data
            .sort((x, y) => (x.title.rendered > y.title.rendered ? 1 : -1))
            .slice(Math.ceil(result.data.length / 2), result.data.length)
        );
      } catch (e) { }
    };
    fetchData();
  }, []);

  return column1.length && column2.length ? (
    <div className="row d-flex content justify-content-end react-transition fade-in">
      <div className="col-md-3"></div>
      <div className="col-md-9 autores">
        <h2>Autoría</h2>
        <div className="row">
          <div className="col-md-6">
            {column1.map((author, i) => {
              return (
                <div
                  key={i}
                  className="autores"
                  dangerouslySetInnerHTML={{
                    __html:
                      `<h3>${author.title.rendered}</h3>` +
                      author.content.rendered
                  }}
                />
              );
            })}
          </div>
          <div className="col-md-6">
            {column2.map((author, i) => {
              return (
                <div
                  key={i}
                  className="autores"
                  dangerouslySetInnerHTML={{
                    __html:
                      `<h3>${author.title.rendered}</h3>` +
                      author.content.rendered
                  }}
                />
              );
            })}
          </div>
        </div>
        {/* array.sort((x, y) => x.propertyName > y.propertyName ? 1 : -1)) */}
      </div>
      <ArrowToTop />
    </div>
  ) : (
      <Spinner />
    );
}

export default Autores;

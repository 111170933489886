import React, { useEffect, useState } from "react";
import { categoriesIDs, inverseCategoriesIDs } from "../../api/categoriesId";
import wpAPI from "../../api/wpAPI";
import allPDFs from "../../assets/allPDFs";

/* eslint-disable no-unused-vars */
function Pdf({ pdfNum }) {
  const [temaData, setTemaData] = useState([]);
  const [categNum, setCategNum] = useState(1);
  const [localCategoryId, setLocalCategoryId] = useState(1);

  useEffect(() => {
    categQuery();
    // eslint-disable-next-line
  }, [localCategoryId]);

  const categQuery = () => {
    // let requiredCateg = categoryId;
    let requiredCategId = categoriesIDs.get(localCategoryId);
    // setCategId(requiredCategId);

    const fetchData = async () => {
      try {
        const result = await wpAPI.get(`/categories/${requiredCategId}`);
        const tema = await wpAPI.get(`/categories/?parent=${requiredCategId}`);


        // setCategData(result.data);
        setTemaData(tema.data);
        setCategNum(inverseCategoriesIDs.get(result.data.id));
      } catch (e) { }
    };
    fetchData();
  };

  const currentPdf = allPDFs[`PDF${pdfNum}`];

  return (
    <div className="row">
      <div className="col">Pdfs</div>
      <a target="blank" href={currentPdf}>
        ver PDF
      </a>
    </div>
  );
}
/* eslint-enable no-unused-vars */
export default Pdf;

import React from "react";

function Politicas() {
  return (
    <>

      <p>
        La confidencialidad y la seguridad son valores primordiales de
        INTERNATIONAL MARKETING & COMMUNICATION S.A. (en adelante IMC) y, en
        consecuencia, asumimos el compromiso de garantizar la privacidad del
        Usuario en todo momento y de no recabar información innecesaria. A
        continuación, le proporcionamos toda la información necesaria sobre
        nuestra Política de Privacidad en relación con los datos personales
        que recabamos, explicándole:
        </p>

      <ul>
        <li>Quién es el responsable del tratamiento de sus datos.</li>
        <li>Para qué finalidades recabamos los datos que le solicitamos.</li>
        <li>Cuál es la legitimación para su tratamiento.</li>
        <li>Durante cuánto tiempo los conservamos.</li>
        <li>A qué destinatarios se comunican sus datos.</li>
        <li>Cuáles son sus derechos.</li>
      </ul>

      <ol>
        <li>
          <h4>Responsable:</h4>
          <p>
            INTERNATIONAL MARKETING & COMMUNICATION, S.A.<br />NIF nº A79107009<br />
            Dirección: C/ Alberto Alcocer, 13-1º D<br />
            Madrid (28036)<br />
            e-mail: faqs@habilidades-clinicas.com.
            </p>
        </li>
        <li>
          <h4>
            Finalidades, legitimación y conservación de los tratamientos de
            los datos enviados a través de:
            </h4>

          <ul>
            <li>
              <h5>FORMULARIO DE REGISTRO.</h5>

              <p>
                <strong>Finalidades:</strong>
              </p>

              <ul>
                <li>
                  Darle de alta como usuario y permitirles el acceso a los
                  servicios de nuestra web.
                  </li>
                <li>
                  Remitirle información comercial de nuestros productos y
                  servicios, incluso por medios electrónicos.
                  </li>
                <li>
                  Remitirle información comercial de productos y servicios de
                  las sociedades y empresas patrocinadoras, inclusive por
                  medios electrónicos si marca la casilla de aceptación.
                  </li>
              </ul>

              <p>
                <strong>Legitimación:</strong>
              </p>
              <ul>
                <li>Consentimiento del usuario al registrarse.</li>
                <li>
                  Interés legítimo en mantener informados a nuestros usuarios
                  registrados.
                  </li>
                <li>
                  El consentimiento del usuario, al marcar la casilla de
                  aceptación, para recibir información comercial de terceros.
                  </li>
              </ul>

              <p>
                <strong>Conservación:</strong> Hasta que el usuario registrado
                  solicite su baja y posteriormente durante los plazos
                  legalmente previstos.
                </p>
            </li>

            <li>
              <h5>ENVÍO DE CORREOS ELECTRÓNICOS.</h5>

              <p>
                <strong>Finalidad:</strong> Contestar a sus solicitudes de
                  información, atender sus peticiones y responder sus consultas
                  o dudas.
                </p>

              <p>
                <strong>Legitimación: </strong>El consentimiento del usuario
                  al solicitarnos información a través de la dirección de correo
                  electrónico.
                </p>

              <p>
                <strong>Conservación:</strong> Una vez resulta contestada su
                  petición por correo electrónico, si no ha generado un nuevo
                  tratamiento.
                </p>

            </li>


          </ul>
          <h5>
            OBLIGACIÓN DE FACILITARNOS SUS DATOS PERSONALES Y CONSECUENCIAS DE NO HACERLO
                  </h5>
          {/* <h5>
                  Obligación de facilitarnos sus datos personales y
                  consecuencias de no hacerlo.
                  </h5> */}

          <p>
            El suministro de datos personales requiere una edad mínima de
            14 años, o en su caso, disponer de capacidad jurídica
            suficiente para contratar.
                </p>
          <p>
            Los datos personales solicitados son necesarios para gestionar
            sus solicitudes y/o prestarle los servicios que pueda
            contratar, por lo que, si no nos los facilita, no podremos
            atenderle correctamente ni prestarle el servicio que ha
            solicitado.
                </p>
        </li>

        <li>
          <h4>Destinatarios de sus datos</h4>
          <p>
            Sus datos serán cedidos a:
            </p>

          <ul>
            <li>
              Las sociedades patrocinadoras y
              avalistas que figuran en la página web por interés legítimo en
              conocer los profesionales que han accedido a los contenidos
              patrocinados y avalados por ellas.

              </li>
            <li>
              Otras, si existe una obligación legal.
              </li>
          </ul>

        </li>

        <li>
          <h4>Derechos en relación con sus datos personales</h4>
          <p>
            Cualquier persona puede retirar su consentimiento en cualquier
            momento, cuando el mismo se haya otorgado para el tratamiento de
            sus datos. En ningún caso, la retirada de este consentimiento
            condiciona la ejecución del contrato de suscripción o las
            relaciones generadas con anterioridad.
            </p>
          <p>Igualmente, puede ejercer los siguientes derechos:</p>
          <ul>
            <li>
              Solicitar el acceso a sus datos personales o su rectificación
              cuando sean inexactos.
              </li>
            <li>
              Solicitar su supresión cuando, entre otros motivos, los datos ya
              no sean necesarios para los fines para los que fueron recogidos.
              </li>
            <li>
              Solicitar la limitación de su tratamiento en determinadas
              circunstancias.
              </li>
            <li>
              Solicitar la oposición al tratamiento de sus datos por motivos
              relacionados con su situación particular.
              </li>
            <li>
              Solicitar la portabilidad de los datos en los casos previstos en
              la normativa.
              </li>
            <li>Otros derechos reconocidos en las normativas aplicables.</li>
          </ul>

          <p>
            Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido
            al responsable a su dirección postal o electrónica (indicadas en
            el apartado A), indicando la referencia “Datos Personales”,
            especificando el derecho que se quiere ejercer y respecto a qué
            datos personales.
            </p>
          <p>
            En caso de divergencias con la empresa en relación con el
            tratamiento de sus datos, puede presentar una reclamación ante la
            Autoridad de Protección de Datos (<a href="https://www.aepd.es/es" target="blank">www.agpd.es</a>).
            </p>
        </li>
        <li>
          <h4>Cookies</h4>
          <p>
            Esta página web, únicamente utiliza cookies técnicas de sesión
            propias que en ningún caso tratan datos de carácter personal ni
            captan hábitos de navegación para fines publicitarios y que se
            eliminan al finalizar la sesión por lo que, de acuerdo con la Ley
            de Servicios de la Sociedad de la Información, no requieren su
            consentimiento para su uso.
            </p>
        </li>
        <li>
          <h4>Seguridad de sus datos personales</h4>
          <p>
            Con el objetivo de salvaguardar la seguridad de sus datos
            personales, le informamos que hemos adoptado todas las medidas de
            índole técnica y organizativa necesarias para garantizar la
            seguridad de los datos personales suministrados de su alteración,
            pérdida, y tratamientos o accesos no autorizados.
            </p>
        </li>
        <li>
          <h4>Actualización de sus datos</h4>
          <p>
            Es importante que para que podamos mantener sus datos personales
            actualizados, nos informe siempre que haya habido alguna
            modificación en ellos, en caso contrario, no respondemos de la
            veracidad de estos.
            </p>

          <p>
            No nos hacemos responsables de la política de privacidad respecto
            a los datos personales que pueda facilitar a terceros por medio de
            los enlaces disponibles en nuestra página web.
            </p>
          <p>
            La presente Política de Privacidad puede ser modificada para
            adaptarlas a los cambios que se produzca en nuestra web, así como
            modificaciones legislativas o jurisprudenciales sobre datos
            personales que vayan apareciendo, por lo que exige su lectura,
            cada vez que nos facilite sus datos a través de esta Web.
            </p>
        </li>
      </ol>
    </>
  );
}

export default Politicas;

const Categs = [
  [1, 4],
  [2, 11],
  [3, 15],
  [4, 17],
  [5, 18],
  [6, 20],
  [7, 23],
  [8, 25],
  [9, 27],
  [10, 32],
  [11, 34],
  [12, 36],
  [13, 39],
  [14, 42],
  [15, 44]
];

const Temas = [
  [1, 5],
  [2, 6],
  [3, 7],
  [4, 8],
  [5, 9],
  [6, 10],
  [7, 12],
  [8, 13],
  [9, 16],
  [10, 2],
  [11, 19],
  [12, 21],
  [13, 22],
  [14, 24],
  [15, 3],
  [16, 26],
  [17, 28],
  [18, 29],
  [18, 29],
  [19, 30],
  [20, 33],
  [21, 35],
  [22, 37],
  [23, 38],
  [24, 40],
  [25, 41],
  [26, 43],
  [27, 14],
  [28, 31],
  [29, 45]
];

export const categoriesIDs = new Map(Categs);

export const inverseCategoriesIDs = new Map(Categs.map(([k, v]) => [v, k]));

export const temasIDs = new Map(Temas);

export const inverseTemasIDs = new Map(Temas.map(([k, v]) => [v, k]));

import React from "react";
import PdfForCateg from "./PdfForCateg";

const PdfPageByCategory = React.memo(() => {

  return (
    <div className="row react-transition fade-in">
      <div className="col">
        <div className="row d-flex justify-content-end mb-3">
          <div className="col-md-9">
            <h2>PDFs</h2>
          </div>
        </div>

        <div className="row d-flex justify-content-end">
          <div className="col-md-9">
            <div className="row">
              {Array.from({ length: 15 }).map((el, i) => (
                <div key={i} className="col-md-4">
                  <PdfForCateg localCategoryId={i + 1} pdfNum={i + 1} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PdfPageByCategory;

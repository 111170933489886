import React, { useContext } from "react";
import { Link } from "react-router-dom";
import videos from "../api/videos";
import iconImages from "../assets/images";
import ContentContext from "../context/content/contentContext";

function Video(props) {
  const contentContext = useContext(ContentContext);

  const { categoryId } = contentContext;
  const videoId = props.match.params.videoId;
  return (
    <>
      <div className="row mt-5 mb-2">
        <div className="col d-flex justify-content-around align-items-end">
          <p className="alert alert-success" style={{ maxWidth: "640px" }}>
            <strong>
              <span style={{ color: "#009362" }}>
                Password para visualizar vídeo:
              </span>{" "}
              SEMERGEN
            </strong>
          </p>
          <div className="d-flex flex-column justify-content-end align-items-end">
            <div>
              <img
                style={{ width: "35px", marginRight: "8px" }}
                src={iconImages[categoryId - 1]}
                alt=""
              />
              Sección: {categoryId} - <strong>Tema: {videoId}.</strong>{" "}
              {props.location.state && props.location.state.tema}
            </div>
            <Link className="volver-a-content" to={`../tema/${videoId}`}>
              <span>{"< "} Contenido principal</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col d-flex justify-content-center">
          <iframe
            className="video"
            title={`video-${videoId}`}
            src={`${videos[videoId]}`}
            width="740"
            height="416"
            frameBorder="0"
            allowFullScreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Video;

import React from "react";
import { handleGoToTop } from "../../utils/handleGoToTop";

function ArrowToTop({ handle, arrowClass, show }) {
  return (
    <div className="row">
      <div onClick={handleGoToTop} className="col go-to-top">
        <i className="fa fa-angle-up"></i>
      </div>
    </div>
  );
}

export default ArrowToTop;

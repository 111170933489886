import React, { useReducer } from "react";
import ContentContext from "./contentContext";
import contentReducer from "./contentReducer";

const ContentState = props => {
  const initialState = {
    categoryId: 1,
    themes: [],
    theme: null
  };

  const [state, dispatch] = useReducer(contentReducer, initialState);

  return (
    <ContentContext.Provider
      value={{
        categoryId: state.categoryId,
        themes: state.themes,
        iconNum: state.iconNum,
        dispatch
      }}
    >
      {props.children}
    </ContentContext.Provider>
  );
};

export default ContentState;

import axios from "axios";

export default axios.create({
  baseURL: "/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    xsrfHeaderName: "XSRF-TOKEN",
    "Access-Control-Allow-Origin": "*"
  }
});

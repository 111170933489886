import React from "react";
import { Link } from "react-router-dom";

function AvisoLegal() {
  return (
    <div className="row content content-container legales react-transition fade-in mt-5">
      <div className="col">
        <h3>AVISO LEGAL</h3>
        <p>
          Esta página Web es propiedad de INTERNATIONAL MARKETING & COMMUNICATION, S.A. (en adelante IMC), con NIF nº A79107009 y domicilio calle Alberto Alcocer, 13-1º D, Madrid (28036) e inscrita en el Registro Mercantil de Madrid con los siguientes datos: tomo 9.466, general 8.209 de la sección 3ª. folio, 109, hoja 87.858-2, inscripción 1ª.</p>
        <p>Para cualquier consulta o propuesta, contáctenos en el e-mail faqs@habilidades-clinicas.com.</p>
        <p>Esta página Web se rige por la normativa exclusivamente aplicable en España, quedando sometida a ella, tanto nacionales como extranjeros que utilicen esta Web.</p>
        <p>El acceso a nuestra página Web por parte del USUARIO es gratuito y está condicionado a la previa lectura y aceptación Íntegra, expresa y sin reservas de las presentes CONDICIONES GENERALES DE USO vigentes en el momento del acceso, que rogamos lea detenidamente. El USUARIO en el momento que utiliza nuestro portal, sus contenidos o servicios, acepta y se somete expresamente a las condiciones generales de uso de este. Si el usuario no estuviere de acuerdo con las presentes condiciones de uso, deberá abstenerse de utilizar este portal y operar por medio de este.</p>
        <p>El suministro de datos personales y la inscripción a cursos de formación a través de nuestro portal requiere una edad mínima de 18 años, o en su caso, disponer de capacidad jurídica suficiente para contratar.</p>
        <p>En cualquier momento podremos modificar la presentación y configuración de nuestra Web, ampliar o reducir servicios, e incluso suprimirla de la Red, así como los servicios y contenidos prestados, todo ello de forma unilateral y sin previo aviso.</p>

        <h4>A. PROPIEDAD INTELECTUAL</h4>
        <p>Todos los contenidos, textos, imágenes, y códigos fuente son propiedad de INTERNATIONAL MARKETING & COMMUNICATION, S.A o de terceros a los que se han adquirido sus derechos de explotación, y están protegidos por los derechos de Propiedad Intelectual e Industrial.</p>
        <p>El usuario únicamente tiene derecho a un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa para modificarlos, reproducirlos, explotarlos, distribuirlos o ejercer cualquier derecho perteneciente a su titular.</p>
        <p>Habilidades Clínicas e IMC son marcas registradas, y está prohibida su reproducción, imitación, utilización o inserción de estas marcas sin nuestra debida autorización.</p>
        <p>Las marcas y logotipos de las sociedades patrocinadoras y avalistas son marcas registradas por sus titulares y se reproducen con su autorización.</p>
        <p>El establecimiento de enlaces a nuestro portal no confiere ningún derecho sobre el mismo. Asimismo, el simple hecho de establecer un enlace a nuestra página web no da derecho a otorgarse la categoría de colaborador o socio.</p>
        <p>IMC queda exonerado de responsabilidad por cualquier reclamación respecto a los derechos de propiedad intelectual de los contenidos e imágenes publicadas por terceros en su portal.</p>
        <p>Está absolutamente prohibida la imitación ya sea total o parcial de nuestro portal.</p>

        <h4>B. CONDICIONES DE ACCESO Y USO</h4>
        <p>El acceso a nuestra página Web es gratuito, sin embargo, el acceso a los servicios es de acceso restringido y requiere haber realizado un proceso de registro y la identificación mediante contraseñas.</p>

        <h4>C. POLÍTICA DE PRIVACIDAD</h4>
        <p>La confidencialidad y la seguridad son valores primordiales de IMC y, en consecuencia, asumimos el compromiso de garantizar la privacidad del Usuario en todo momento y de no recabar información innecesaria.</p>
        <p>El suministro de datos personales a través de nuestro portal requiere la aceptación expresa de nuestra <Link style={{ fontWeight: '900' }} className="" to="/politica-de-privacidad">Política de privacidad</Link>.</p>

        <h4>D. RESPONSABILIDADES</h4>
        <p>Al poner a disposición del usuario esta página Web queremos ofrecerle un servicio de calidad, utilizando la máxima diligencia en la prestación de este, así como en los medios tecnológicos utilizados. No obstante, no responderemos de la presencia de virus y otros elementos que de algún modo puedan dañar el sistema informático del usuario.</p>
        <p>IMC no garantiza que la disponibilidad del servicio sea continua e ininterrumpida, por circunstancias originadas por problemas en la red de Internet, averías en los dispositivos informáticos u otras circunstancias imprevisibles, de manera que el USUARIO acepta soportar dentro de unos límites razonables estas circunstancias, por lo que renuncia expresamente a reclamar a Habilidades Clínicas cualquier responsabilidad por los posibles fallos, errores y uso del servicio.</p>
        <p>El USUARIO asume toda la responsabilidad derivada del uso de nuestra página web, siendo el único responsable de todo efecto directo o indirecto que sobre la página web se derive, incluyendo, de forma enunciativa y no limitativa, todo resultado económico, técnico y/o jurídico adverso, así como la defraudación de las expectativas generadas por nuestro portal, obligándose el usuario a mantener indemne a Habilidades Clínicas por cualesquiera reclamaciones derivadas, directa o indirectamente de tales hechos.</p>
        <p>IMC no garantiza la exactitud, veracidad y vigencia de los contenidos de esta página web, ya sean propios, de terceros, o enlazables a otras webs, quedando totalmente exonerada de cualquier responsabilidad derivada del uso de estos.</p>
        <p>IMC queda exonerada de cualquier responsabilidad derivada de cualquier reclamación, incluido el pago de honorarios a abogados, por las demandas y reclamaciones originadas por terceros por el incumplimiento por parte del USUARIO de nuestras condiciones de uso, acceso y política de privacidad, o cualquiera otra reclamación por el incumplimiento de la legislación vigente.</p>
        <p>El USUARIO reconoce que ha entendido toda la información respecto a las condiciones de uso de nuestro portal, y reconoce que son suficientes para la exclusión del error en las mismas, y por lo tanto, las acepta integra y expresamente.</p>
        <p>El USUARIO es plenamente consciente de que la mera navegación por la presente página web, así como la utilización de sus servicios, implica la aceptación de las presentes condiciones de uso.</p>
        <p>Todo lo referente a nuestra página web, se rige exclusivamente por las leyes españolas. En el caso de que se produzca cualquier tipo de discrepancia o diferencia entre las partes en relación con la interpretación y contenido de la presente página web, todas las partes se someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de Madrid.</p>

        <h4>E. VIGENCIA DE LAS CONDICIONES GENERALES DE ACCESO A LA WEB</h4>
        <p>Las presentes Condiciones Generales de Uso han sido modificadas con fecha 26/02/2020. En cualquier momento podemos proceder a su modificación: por favor, compruebe la fecha de emisión en cada ocasión en que se conecte a nuestra página Web y así tendrá la certeza de que no se ha producido modificación alguna que le afecte.</p>
        <p>Para cualquier cuestión respecto a las Condiciones de Uso de nuestra página web, puede ponerse en contacto con nosotros en los datos arriba indicados, o con Lant Abogados en  <a href="mailto:info@lant-abogados.com">info@lant-abogados.com</a> o en <a href="https://www.lant-abogados.com/" target="blank">www.lant-abogados.com</a>.
        </p>

      </div>
    </div>
  );
}

export default AvisoLegal;
